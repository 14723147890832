const env = {
  development: {
    BASE_URL: 'http://localhost:3000',
    API_URL: 'http://localhost:8000',
    SOCKET_URL: 'http://localhost:8000',
  },
  production: {
    BASE_URL: 'https://adm.exgool.com',
    API_URL: 'https://api.exgool.com',
    SOCKET_URL: 'https://api.exgool.com',
  },
};

export default env;
