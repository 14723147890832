import React, { useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Input from '@/components/Input';
import Select from '@/components/Select';
import api from '@/services/api';
import useBeforeUnload from '@/hooks/useBeforeUnload';

import { getPlayerPositionSelectOptions } from '@/pages/AthleteCards/utils';
import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';

const { confirm } = Modal;

function NewPlayer({ team, getTeam }) {
  const history = useHistory();

  const me = useSelector((state) => state.user);

  useBeforeUnload({
    when: true,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a criação do jogador?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    return history.block(JSON.stringify(messageComponents));
  }, [history]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const validationSchema = useMemo(() => {
    const isHouseTeam = team.teamType === 'house';

    let numberValidator;
    let positionValidator;

    if (isHouseTeam) {
      numberValidator = Yup.number().typeError(
        'O número da camisa informado não é válido'
      );
    } else {
      numberValidator = Yup.number()
        .required('O número da camisa do jogador é obrigatório')
        .typeError('O número da camisa informado não é válido');
      positionValidator = Yup.string(
        'A posição do jogador é obrigatória'
      ).required('A posição do jogador é obrigatória');
    }

    return Yup.object().shape({
      name: Yup.string().required('O nome do jogador é obrigatório'),
      number: numberValidator,
      position: positionValidator,
      age: Yup.number().typeError('A idade informada não é válida'),
      height: Yup.number().typeError('A altura informada não é válida'),
      weight: Yup.number().typeError('O peso informado não é válido'),
    });
  }, [team]);

  const handleSubmit = useCallback(
    async (values) => {
      await new Promise((resolve) => {
        confirm({
          title: 'Deseja realmente criar esse jogador?',
          icon: <ExclamationCircleOutlined />,
          content:
            'Ao ser criado, ele será listado dentro do time para todos os usuários',
          cancelText: 'Cancelar',
          okText: 'Criar jogador',
          onOk() {
            resolve(true);
          },
        });
      });

      const body = {
        player: {
          ...values,
          age: values.age || '--',
          height: values.height ? `${values.height} m` : '--',
          weight: values.height ? `${values.weight} kg` : '--',
          from: values.from || '--',
        },
        status: 'new',
      };

      try {
        await api.put(`/api/players/${team._id}`, body);

        toast.success('Jogador adicionado com sucesso!');
        unblockPage();

        getTeam();

        if (me.type === 'admin') return history.push(`/teams/${team._id}`);
        if (me.type === 'team') return history.push(`/my_team`);
      } catch (error) {
        console.log(error);

        toast.error('Aconteceu um erro inesperado ao criar esse jogador!');
      }
      return null;
    },
    [getTeam, history, me.type, team._id, unblockPage]
  );

  const eneableSubmitButton = useCallback(({ errors }) => {
    if (Object.entries(errors).length > 0) {
      return false;
    }

    return true;
  }, []);

  return (
    <Container>
      <TitlePageContainer>
        <h5>Novo jogador</h5>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <Formik
          initialValues={{
            name: '',
            number: '',
            position: null,
            age: '',
            height: '',
            weight: '',
            from: '',
          }}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <FastField name="name">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Nome do jogador"
                      placeholder="Digite o nome do jogador"
                      identifier="name"
                      type="text"
                      error={meta?.touched && meta?.error}
                    />
                  );
                }}
              </FastField>
              <FastField name="number">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Número da camisa"
                      placeholder="Digite o número da camisa"
                      identifier="number"
                      type="text"
                      error={meta?.touched && meta?.error}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </FastField>
              <FastField name="position">
                {({ field, meta, form }) => {
                  return (
                    <Select
                      {...field}
                      label="Posição no campo"
                      identifier="position"
                      placeholder="Selecione uma posição"
                      options={getPlayerPositionSelectOptions()}
                      error={meta?.touched && meta?.error}
                      onChange={(value) => {
                        form.setFieldValue('position', value);
                      }}
                    />
                  );
                }}
              </FastField>
              <FastField name="age">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Idade"
                      placeholder="Digite o número da camisa"
                      addonAfter="anos"
                      identifier="age"
                      type="text"
                      error={meta?.touched && meta?.error}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </FastField>
              <FastField name="height">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Altura"
                      placeholder="Digite a altura do jogador"
                      addonAfter="m"
                      identifier="height"
                      type="text"
                      error={meta?.touched && meta?.error}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/,/g, '.');
                        e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </FastField>
              <FastField name="weight">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Peso"
                      placeholder="Digite o peso do jogador"
                      addonAfter="kg"
                      identifier="weight"
                      type="text"
                      error={meta?.touched && meta?.error}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/,/g, '.');
                        e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </FastField>
              <FastField name="from">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="País de origem"
                      placeholder="Digite o país de origem do jogador"
                      identifier="from"
                      type="text"
                      error={meta?.touched && meta?.error}
                    />
                  );
                }}
              </FastField>
              <ButtonsContainer>
                <Button
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !eneableSubmitButton(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting
                    ? 'Criar jogador'
                    : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default NewPlayer;
