import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Modal } from 'antd';
import { MdEdit } from 'react-icons/md';
import { FiTrash2, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';

import { UploadRequests } from '@/services/api/requests/Upload';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';

import CustomAntButton from '@/components/CustomAntButton';
import Input from '@/components/Input';
import Select from '@/components/Select';

import { Header } from '../styles';
import {
  Container,
  Player,
  PlayerMainContent,
  PlayerRestContent,
  EditPlayerContainer,
  ButtonsContainer,
  ActionButtonAvatar,
} from './styles';
import {
  getAthleteNumberDescription,
  getPlayerPositionLabel,
  getPlayerPositionSelectOptions,
} from '@/pages/AthleteCards/utils';

function eneableSubmitButton({ errors }) {
  if (Object.entries(errors).length > 0) {
    return false;
  }

  return true;
}

function PlayersList({
  playersWithControllerOptions,
  setPlayersWithControllerOptions,
  handleEditPlayerData,
  handleSubmitUpdatePlayer,
  handleSubmitDeletePlayer,
  handleToogleEditPlayerAvatar,
}) {
  const { url } = useRouteMatch();

  const [avatarModalVisible, setAvatarModalVisible] = useState(null);

  function getPlayerValidationSchema(player) {
    const isHouseTeam = player.type === 'house';

    let numberValidator;
    let positionValidator;

    if (isHouseTeam) {
      numberValidator = Yup.number().typeError(
        'O número da camisa informado não é válido'
      );
    } else {
      numberValidator = Yup.number()
        .required('O número da camisa do jogador é obrigatório')
        .typeError('O número da camisa informado não é válido');
      positionValidator = Yup.string().required(
        'A posição do jogador é obrigatória'
      );
    }

    return Yup.object().shape({
      name: Yup.string().required('O nome do jogador é obrigatório'),
      number: numberValidator,
      position: positionValidator,
      age: Yup.number('A idade informada não é válida'),
      height: Yup.number('A altura informada não é válida'),
      weight: Yup.number('O peso informado não é válido'),
    });
  }

  return (
    <>
      <Header>
        <h5>Jogadores</h5>
        <Link to={`${url}/new_player`}>
          <CustomAntButton type="primary">Novo jogador</CustomAntButton>
        </Link>
      </Header>
      <Container>
        {playersWithControllerOptions.map((player) => (
          <React.Fragment key={player._id}>
            {!player.inEditing ? (
              <Player
                onClick={() => {
                  setPlayersWithControllerOptions((oldState) => {
                    return oldState.map((oldStatePlayer) => {
                      if (oldStatePlayer._id === player._id) {
                        return {
                          ...oldStatePlayer,
                          collapsedOnList: !oldStatePlayer.collapsedOnList,
                        };
                      }

                      return {
                        ...oldStatePlayer,
                        collapsedOnList: false,
                      };
                    });
                  });
                }}
              >
                <PlayerMainContent>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setAvatarModalVisible(player);
                    }}
                  >
                    <img
                      src={
                        player?.photo
                          ? UploadRequests.getFileUrl(player.photo)
                          : player?.photoUri || DefaultAvatar
                      }
                      alt={player.name}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = DefaultAvatar;
                      }}
                    />
                  </button>
                  <div>
                    <strong>{player.name}</strong>
                    {player.number && player.position && (
                      <small>
                        {getAthleteNumberDescription(player.number)}
                        {getPlayerPositionLabel(player.position)}
                      </small>
                    )}
                  </div>
                  <div>
                    <CustomAntButton
                      type="text"
                      useTextPrimary
                      icon={<MdEdit height={18} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditPlayerData(player._id);
                      }}
                    />
                    <CustomAntButton
                      type="text"
                      danger
                      icon={<FiTrash2 height={18} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSubmitDeletePlayer(player._id);
                      }}
                    />
                    <div>
                      {!player.collapsedOnList ? (
                        <FiChevronDown size={18} />
                      ) : (
                        <FiChevronUp size={18} />
                      )}
                    </div>
                  </div>
                </PlayerMainContent>
                {player.collapsedOnList && (
                  <PlayerRestContent>
                    <li>
                      <small>Idade</small>
                      <p>{player.age} anos</p>
                    </li>
                    <li>
                      <small>Altura</small>
                      <p>{player.height}</p>
                    </li>
                    <li>
                      <small>Peso</small>
                      <p>{player.weight}</p>
                    </li>
                    <li>
                      <small>País de origem</small>
                      <p>{player.from}</p>
                    </li>
                  </PlayerRestContent>
                )}
              </Player>
            ) : (
              <EditPlayerContainer>
                <Formik
                  initialValues={{
                    _id: player._id,
                    name: player.name || '',
                    number: player.number || '',
                    position: player.position || null,
                    age: player.age || '',
                    height: player.height
                      ? String(player.height).replace(/[^0-9.]/g, '')
                      : '',
                    weight: player.weight
                      ? String(player.weight).replace(/[^0-9.]/g, '')
                      : '',
                    from: player.from || '',
                  }}
                  validationSchema={() => {
                    return getPlayerValidationSchema(player);
                  }}
                  validateOnMount
                  onSubmit={handleSubmitUpdatePlayer}
                >
                  {(formikProps) => (
                    <form onSubmit={formikProps.handleSubmit}>
                      <FastField name="name">
                        {({ field, meta }) => {
                          return (
                            <Input
                              {...field}
                              label="Nome do jogador"
                              placeholder="Digite o nome do jogador"
                              identifier="name"
                              type="text"
                              error={meta?.error}
                            />
                          );
                        }}
                      </FastField>
                      <FastField name="number">
                        {({ field, meta }) => {
                          return (
                            <Input
                              {...field}
                              label="Número da camisa"
                              placeholder="Digite o número da camisa"
                              identifier="number"
                              type="text"
                              error={meta?.error}
                              onChange={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ''
                                );
                                field.onChange(e);
                              }}
                            />
                          );
                        }}
                      </FastField>
                      <FastField name="position">
                        {({ field, meta, form }) => {
                          return (
                            <Select
                              {...field}
                              label="Posição no campo"
                              identifier="position"
                              placeholder="Selecione uma posição"
                              options={getPlayerPositionSelectOptions()}
                              error={meta?.touched && meta?.error}
                              onChange={(value) => {
                                form.setFieldValue('position', value);
                              }}
                            />
                          );
                        }}
                      </FastField>
                      <FastField name="age">
                        {({ field, meta }) => {
                          return (
                            <Input
                              {...field}
                              label="Idade"
                              placeholder="Digite o número da camisa"
                              addonAfter="anos"
                              identifier="age"
                              type="text"
                              error={meta?.error}
                              onChange={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ''
                                );
                                field.onChange(e);
                              }}
                            />
                          );
                        }}
                      </FastField>
                      <FastField name="height">
                        {({ field, meta }) => {
                          return (
                            <Input
                              {...field}
                              label="Altura"
                              placeholder="Digite a altura do jogador"
                              addonAfter="m"
                              identifier="height"
                              type="text"
                              error={meta?.error}
                              onChange={(e) => {
                                e.target.value = e.target.value.replace(
                                  /,/g,
                                  '.'
                                );
                                e.target.value = e.target.value.replace(
                                  /[^0-9.]/g,
                                  ''
                                );
                                field.onChange(e);
                              }}
                            />
                          );
                        }}
                      </FastField>
                      <FastField name="weight">
                        {({ field, meta }) => {
                          return (
                            <Input
                              {...field}
                              label="Peso"
                              placeholder="Digite o peso do jogador"
                              addonAfter="kg"
                              identifier="weight"
                              type="text"
                              error={meta?.error}
                              onChange={(e) => {
                                e.target.value = e.target.value.replace(
                                  /,/g,
                                  '.'
                                );
                                e.target.value = e.target.value.replace(
                                  /[^0-9.]/g,
                                  ''
                                );
                                field.onChange(e);
                              }}
                            />
                          );
                        }}
                      </FastField>
                      <FastField name="from">
                        {({ field, meta }) => {
                          return (
                            <Input
                              {...field}
                              label="País de origem"
                              placeholder="Digite o país de origem do jogador"
                              identifier="from"
                              type="text"
                              error={meta?.error}
                            />
                          );
                        }}
                      </FastField>
                      <ButtonsContainer>
                        <CustomAntButton
                          htmlType="button"
                          onClick={() => handleEditPlayerData(player._id)}
                        >
                          Cancelar
                        </CustomAntButton>
                        <CustomAntButton
                          htmlType="submit"
                          disabled={
                            formikProps.isSubmitting ||
                            !eneableSubmitButton(formikProps)
                          }
                          type="primary"
                        >
                          {!formikProps.isSubmitting
                            ? 'Atualizar dados do jogador'
                            : 'Carregando...'}
                        </CustomAntButton>
                      </ButtonsContainer>
                    </form>
                  )}
                </Formik>
              </EditPlayerContainer>
            )}
          </React.Fragment>
        ))}
      </Container>
      <Modal
        title="Avatar do usuário"
        visible={!!avatarModalVisible}
        onCancel={() => {
          setAvatarModalVisible(null);
        }}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        // cancelButtonProps={{
        //   disabled: loadingRemoveUserAvatar,
        // }}
        cancelText="Voltar"
      >
        <ActionButtonAvatar
          onClick={() => {
            handleToogleEditPlayerAvatar(avatarModalVisible);
            setAvatarModalVisible(null);
          }}
        >
          <MdEdit size={24} />
          <span>Atualizar avatar</span>
        </ActionButtonAvatar>
      </Modal>
    </>
  );
}

export default PlayersList;
